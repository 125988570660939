/* 
Styles - Main Website
*/

/* Core */
:root {
  --primary: #0084ff;
  --primary-transparency: rgba(0, 85, 255, 0.8);
  --dark: #303030;
  --dark-trans: rgba(48, 48, 48, 0.4);
  --light: #fff;
  --background: #5a5a5a;
  --box-shadow: 0 1px 20px rgba(43, 43, 43, 0.8);
  --font-shadow: 0 1px 5px rgba(34, 34, 34, 0.8);
  --borderradius: 10px;
  --background-size-images: cover;
  /* shadow: horizontal vertical blur color */
}

h1,
p {
  text-shadow: var(--font-shadow);
}

html {
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  color: var(--light);
  margin: 0;
  padding: 0;
}

body {
  /* background-image: url(img/0screenshot00.jpg); */
  margin: 0;
  padding: 0;
  border: 0;
  background-image: url(../images/tsce-2.jpg);
  background-size: var(--background-size-images);
  background-color: var(--background);
  background-position: center;
  background-attachment: fixed;
  line-height: 1.4;
}

header {
  display: flex;
  justify-content: space-between;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

a {
  text-decoration: none;
  color: var(--light);
  margin-left: 5px;
  margin-right: 5px;
}

.page {
  margin: 0;
  padding: 0;
  width: 100%;
}

.container p,
h1 {
  padding: 0px;
  margin: 0px;
  margin: 40px 300px;
}

.container table {
  margin: 40px 300px;
  border: 1px solid #000;
}
/* Nav Bar */
.icon {
  width: 5%;
  height: auto;
  margin-left: 30px;
}

.nav-bar {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-right: 25px;
}

.nav-btn {
  padding: 10px;
}

.nav-btn:hover {
  border-bottom: 2px solid var(--primary);
}

/* Intro / Welcome */

.welcome h1 {
  color: var(--light);
  font-size: 3em;
  padding: 20px;
  /* background-color: var(--dark-trans); */
  border-radius: 10px;
  text-align: center;
  margin: 250px 500px;
  margin-bottom: 500px;
}

.legendary {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
}

/* What is  */

/* .text-section p,
h1 {
  margin: 0;
  padding: 0;
} */

.text-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  padding: 40px;
  box-shadow: var(--box-shadow);
}

.text-section p {
  font-size: 1.3em;
  text-shadow: var(--font-shadow);
}

.title {
  width: 100%;
  text-align: center;
  font-size: 1.5em;
}

.title h1 {
  margin: 0;
  padding: 0;
}

.text p {
  text-align: center;
}

.link {
  border-bottom: 2px solid var(--primary);
}

.link:hover {
  border-bottom: none;
  color: var(--primary);
}

.card-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  padding: 50px;
}
.card {
  background-color: var(--light);
  padding-bottom: 200px;
  width: 350px;
  height: 160px;
  border-radius: var(--borderradius);
  box-shadow: var(--box-shadow);
  color: var(--dark);
  margin: 40px;
  overflow: hidden;
}
.card img {
  border-top-left-radius: var(--borderradius);
  border-top-right-radius: var(--borderradius);
  width: 100%;
}

.card a {
  margin: 0;
  padding: 0;
}

.card p {
  text-shadow: 0px 0px 0px #fff;
  text-align: center;
  margin: 0;
  margin-top: 15px;
  padding: 0px 20px;
}

.card h3 {
  text-align: center;
}

.card a {
  color: #000;
}

.title-text {
  margin: 0;
  padding: 0;
  padding-top: 55px;
  text-align: center;
  font-size: 3em;
}

footer {
  background: var(--primary);
  padding: 1px;
  text-align: center;
}

/* .test-b p {
  text-shadow: var(--font-shadow);
  font-size: 4em;
} */

/* .test {
  background-image: url(../images/spv3_5_full.jpg);
  background-color: var(--dark);
  background-image: url(../images/portent-1.jpg); 
  background-position: center;
  background-attachment: fixed;
  padding: 500px;
}

.test-b {
  background-color: var(--dark);
  padding: 100px;
  box-shadow: var(--box-shadow);
  border-radius: 20px;
} */

/* Backgrounds */

/* Maps */
.spv3 {
  background-image: url(../images/spv3_5_full.jpg);
  background-position: center;
  background-attachment: fixed;
  background-size: var(--background-size-images);
  background-color: var(--background);
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */
}
.sp {
  background-image: url(../images/lumoria.jpg);
  background-position: center;
  background-attachment: fixed;
  background-size: var(--background-size-images);
  background-color: var(--background);
}
.firefight {
  background-image: url(../images/portent-2.jpg);
  background-position: center;
  background-attachment: fixed;
  background-size: var(--background-size-images);
  background-color: var(--background);
}
.arrival {
  background-image: url(../images/arrival-1.jpg);
  background-position: center;
  background-attachment: fixed;
  background-size: var(--background-size-images);
  background-color: var(--background);
}
.addons {
  background-image: url(../images/tsce-4.jpg);
  background-position: center;
  background-attachment: fixed;
  background-size: var(--background-size-images);
  background-color: var(--background);
}
.community {
  background-image: url(../images/tsce1_full.jpg);
  background-position: center;
  background-attachment: fixed;
  background-size: var(--background-size-images);
  background-color: var(--background);
}

/* Textures */

.circuits {
  background-image: url(../images/techdensecircuits.jpg);
  background-position: center;
  background-color: var(--background);
}
.rocks {
  background-image: url(../images/detailcliffrocksmooth.jpg);
  background-position: center;
  background-color: var(--background);
}
.snow {
  background-image: url(../images/b40cliffsnowbump.jpg);
  background-position: center;
  background-color: var(--background);
}
.grass {
  background-image: url(../images/detailgrass.jpg);
  background-position: center;
  background-color: var(--background);
}
.panel {
  background-image: url(../images/techrackplate.jpg);
  background-position: center;
  background-color: var(--background);
  /* background-attachment: fixed; */
  /* background-size: cover; */
}
.covie {
  background-image: url(../images/covenantmetalgeneric.jpg);
  background-position: center;
  background-color: var(--background);
  /* background-attachment: fixed; */
  /* background-size: cover; */
}
.cliffs {
  background-image: url(../images/cliffs.jpg);
  background-position: center;
  background-color: var(--background);
  /* background-attachment: fixed; */
  /* background-size: cover; */
}

@media (max-width: 1350px) {
  .container p,
  h1 {
    padding: 0px;
    margin: 0px;
    margin: 40px 100px;
  }

  .welcome h1 {
    margin: 250px 100px;
    margin-bottom: 300px;
  }

  .icon {
    visibility: hidden;
  }
  .spv3 {
    background-image: none;
  }
  .arrival {
    background-image: none;
  }
  .sp {
    background-image: none;
  }
  .firefight {
    background-image: none;
  }
  .addons {
    background-image: none;
  }
  .community {
    background-image: none;
  }
  body {
    background-image: none;
  }
}

@media (max-width: 900px) {
  .nav-bar {
    visibility: hidden;
  }

  .title-text {
    font-size: 2em;
  }

  .text-section p,
  h1 {
    font-size: 1em;
  }
  .container p,
  h1 {
    padding: 0px;
    margin: 0px;
    margin: 40px 40px;
  }
}

@media (max-width: 320px) {
  .container p,
  h1 {
    padding: 0px;
    margin: 0px;
    margin: 40px 0px;
  }

  .welcome h1 {
    margin: 250px 0px;
    margin-bottom: 300px;
    font-size: 2em;
  }

  .title-text {
    font-size: 1em;
  }

  .title {
    font-size: 1em;
  }
  .text-section {
    padding: 10px;
  }

  .card-list {
    padding: 0px;
  }
}
